<template>
    <div class="months">
        <div v-for="(month) in Months" :key="month" 
            @click="goto(month)" 
            :style="`background-image: url(img/calendar/${month.toLowerCase()}.jpg); background-size: cover;` ">
            {{ month }}
            <small class="muted">{{ days(month) }} days</small>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return {
            MonthArray: Array.from(Array(12).keys()),
            Months: [],
        }
    },
    created(){
        for (const month of this.MonthArray) {
            this.Months.push(this.$moment(month + 1, "M").format('MMMM'))
        }
    },
    methods: {
        goto(m){
            this.$router.push("/dailysuperfood/m/" + m)
        },
        days(m){
            const mon = `${this.$store.state.devotionalYear}-${m}-01`
            const date = this.$moment(new Date(mon))
            return this.$moment(date, "YYYY-MM-DD").daysInMonth()
        }
    }
}
</script>

<style scoped>
.months {
    display: flex;
    flex-wrap: wrap;
}
.months div {
    padding: 50px;
    font-size: 16px;
    text-transform: uppercase;
    width: 33.33%;
    border-right: 1px solid #ffffff;
    border-bottom: 1px solid #ffffff;
    color: #ffffff;
    cursor: pointer;
}
.months small {
    text-transform: lowercase;
    display: block;
}
</style>